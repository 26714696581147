<template>
  <Popover placement="bottomLeft" action="click">
    <template v-slot:content>
      <a to="#">
        <FeatherIcons type="printer" />
        <span>{{ $t('printer') }}</span>
      </a>
      <a to="#">
        <FeatherIcons type="book-open" />
        <span>{{ $t('pdf') }}</span>
      </a>
      <a to="#">
        <FeatherIcons type="file-text" />
        <span>{{ $t('googleSheets') }}</span>
      </a>
      <a to="#">
        <FeatherIcons type="x" />
        <span>{{ $t('excelXlsx') }}</span>
      </a>
      <a to="#">
        <FeatherIcons type="file" />
        <span>{{ $t('csv') }}</span>
      </a>
    </template>
    <Button size="small" type="white">
      <FeatherIcons type="download" />
      <span>{{ $t('export') }}</span>
    </Button>
  </Popover>
</template>

<script>
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'ExportButton',
  components: {},
});
</script>
