import "vue-loading-skeleton/dist/style.css"
import 'ant-design-vue/dist/antd.css';
import app from './config/configApp';
import store from '@/vuex/store';
import './static/css/style.css';
import '@/core/plugins/i18n-plugin';
import '@/core/plugins/pinia-plugin';
import '@/core/plugins/router-setup';
import '@/core/plugins/maps';
import '@/core/components/custom';
import '@/core/components/style';

app.config.productionTip = false;
app.use(store);
app.mount('#app');
