<script setup>
import {PageHeaderStyle} from './style';
import VueTypes from 'vue-types';
import {useLocalAuth} from "@/pinia/AuthLocalStore.js";
import {storeToRefs} from "pinia";
import {computed} from "vue";

defineProps({
  title: VueTypes.oneOfType([VueTypes.string, VueTypes.object]),
  subTitle: VueTypes.oneOfType([VueTypes.string, VueTypes.object]),
  bgColor: VueTypes.string,
  classs: VueTypes.string,
  routes: VueTypes.object,
  buttons: VueTypes.array,
  ghost: VueTypes.bool,
  back: VueTypes.bool,
  breadcrumb: VueTypes.object,
})
defineEmits(['onBack'])

const localAuth = useLocalAuth();
const {selected_role} = storeToRefs(localAuth);

const notices = computed(() => {
  const noticesArray = []
  if (selected_role?.value?.tenant?.type === 'Consumer' && selected_role?.value?.tenant?.opp_payment) {
    const payment = selected_role?.value?.tenant?.opp_payment;
    if (payment.bankStatus === 'pending' && payment.contactStatus === 'pending') {
      noticesArray.push(
          {
            message: `De Online Payment Platform bank en contact verificatie is nog niet geverifieerd`,
            description: `Dit kan 1 tot 2 werkdagen duren`,
            type: 'info',
            closable: true,
          }
      )
    } else    if (payment.bankStatus === 'pending') {
      noticesArray.push(
          {
            message: `De Online Payment Platform bank verificatie is nog niet geverifieerd`,
            description: `Dit kan 1 tot 2 werkdagen duren`,
            type: 'info',
            closable: true,
          }
      )
    }else    if (payment.contactStatus === 'pending') {
      noticesArray.push(
          {
            message: `De Online Payment Platform identiteitsverificatie is nog niet geverifieerd`,
            description: `Dit kan 1 tot 2 werkdagen duren`,
            type: 'info',
            closable: true,
          }
      )
    }
  }

  return noticesArray;
})
</script>


<template>
  <div>
    <div style="margin:10px 25px 0px 25px">
      <a-alert v-for="(payment,index) in notices" :key="index" :type="payment.type" :closable="payment.closable" show-icon>
        <template #description>
          {{ payment.description }}
          <div v-if="payment.goToSettings">
            <router-link to="/settings/general"> {{ $t(`common.goToAutomaticPaymentSettings`) }}</router-link>
          </div>
        </template>
        <template #message>
          {{ payment.message }}
        </template>
      </a-alert>
    </div>

    <PageHeaderStyle
        :class="classs"
        :title="title || $slots.title()"
        :subTitle="subTitle || ($slots.subTitle ? $slots.subTitle() : null)"
        :breadcrumb="breadcrumb && breadcrumb"
        :ghost="ghost"
        :extra="$slots.buttons && $slots.buttons()"
    ></PageHeaderStyle>
  </div>
</template>
